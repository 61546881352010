<template>
  <div class="category category--brand">
    <m-breadcrumbs />
    <json-ld-breadcrumb />
    <div
        class="navbar section"
    >
      <div class="navbar__main">
        <SfHeading
            v-if="getCurrentBrand"
            :level="1"
            :title="categoryMetaContent.h1"
            class="navbar__title"
        />
        <div class="navbar__counter">
          <span
              class="navbar__label"
              :class="{ 'navbar__label--hide': isLoading }"
          >
            {{ categoryProductsTotal }}
          </span>
        </div>
      </div>
    </div>

    <ASmoothReflow>
      <MCategoryNP
        v-if="!availableProducts && !isLoading"
        class="category__np"
      />
    </ASmoothReflow>

    <ASmoothReflow>
      <MBrandLogo
        :alt="getCurrentBrand.name"
        :brand-id="getCurrentBrand.pim_brand_id"
        :key="getCurrentBrand.id"
        :promotion-banners="promotionBanners"
      />
      <MBrandPageBanners />
    </ASmoothReflow>
    <no-ssr>
      <MCategorySection
        v-if="!isDesktop"
        class="mobile-only"
      />
    </no-ssr>
    <div class="main section">
      <div class="sidebar">
        <MSidebarMobileButtons
            :filters-count="activeFiltersCount"
            :categories-are-shown="categoriesAreShown"
            @open="isFilterSidebarOpen = true"
            @toggle-categories="toggleCategoriesFilters"
        />
        <OCategoriesFilter
            v-show="isDesktop || categoriesAreShown"
            :categories="categories"
            :is-loading="categoriesIsLoading"
            :show="isDesktop || categoriesAreShown"
            @select="setCurrentCategory"
        />
        <no-ssr>
          <div
              class="sidebar-filters desktop-only"
              v-if="Object.keys(availableFilters).length > 0 && Object.keys(filterLabels).length > 0"
          >
            <SpecialOffersSidebarFilter
                :available-filters="availableFilters"
                :key="Object.keys(availableFilters).length"
                :filter-labels="filterLabels"
                :current-filters="selectedFilters"
                :aggregations="getAggregations"
                @change="changeFilter"
                @submit-filter-price="submitFilterPrice"
                @clear-price="clearPrice"
            />
            <ALoadingSpinner
                v-show="productsIsLoading"
                :size="24"
                :weight="3"
                :is-absolute-position="true"
            />
          </div>
        </no-ssr>
      </div>
      <div class="products">
        <no-ssr>
          <MCategorySection v-if="isDesktop" />
        </no-ssr>
        <no-ssr>
          <div class="products__navbar-container desktop-only">
            <div class="navbar__filter">
              <MCategoryFilterStock
                  @stock-filter-changed="stockFilterChanged"
              />
              <MCategoryFilterPromo
                :promo-count="getPromoCount"
                :key="getPromoCount"
                @promo-filter-changed="promoFilterChanged"
              />
            </div>
            <div class="navbar__sort">
              <SfSelect
                  class="navbar__select sf-select--white sort-by"
                  ref="SortBy"
                  :selected="sortOrder"
                  @change="changeSortOder"
              >
                <SfSelectOption
                    v-for="option in sortOptions"
                    :key="option.id"
                    :value="option.id"
                    class="sort-by__option"
                >
                  {{ $t(option.label) }}
                </SfSelectOption>
                <template #icon>
                  <span class="dropdown-chevron" />
                </template>
              </SfSelect>
            </div>
          </div>
        </no-ssr>
        <no-ssr>
          <MSelectedFilters
              v-if="activeFiltersCount !== 0"
              class="desktop-only"
              :key="Object.keys(selectedFilters).length"
              :filter-categories="selectedFilters"
              :filter-labels="filterLabels"
              @clear="changeFilter"
              @clear-all="clearFilters"
              @clear-price="clearPrice"
              :available-filters="availableFilters"
          />
        </no-ssr>
        <SpecialOffersProductsContent
            :products="products"
            :is-loading="productsIsLoading"
            :alt-name="getCurrentBrand.name"
        />
        <no-ssr>
          <div
            v-if="!isMobile"
            class="bottom-plp-block"
          >
            <OPagination
              v-if="products.length && totalPages > 1"
              :current-page="currentPage"
              :total="totalPages"
              :scroll-top="true"
              :per-page="perPage"
              :is-load-more="isAdditionalLoading"
              @nav-clicked="onPaginationClick"
              @load-more="onLoadMore"
              @page-changed="onPageChanged"
            />
          </div>
        </no-ssr>
      </div>
      <no-ssr>
        <div
          v-if="isMobile"
          class="bottom-plp-block"
        >
          <OPagination
            v-if="products.length && totalPages > 1"
            :current-page="currentPage"
            :total="totalPages"
            :scroll-top="true"
            :per-page="perPage"
            :is-load-more="isAdditionalLoading"
            @nav-clicked="onPaginationClick"
            @load-more="onLoadMore"
            @page-changed="onPageChanged"
          />
        </div>
      </no-ssr>
    </div>

    <ASmoothReflow
        class="category-footer__wrapper"
        v-intersect.once="{ handler: onIntersect, cfg: { rootMargin: '0px' }}"
    >
      <div class="category-footer">
        <template v-if="hasIntersected">
          <MRecentlyViewedProducts :show-qty-permanently="true" />
          <MAdvantages />
          <OSection
              v-if="false"
              :title-heading="$t('Cook with VARUS')"
              class="section"
          >
            <template #link>
              <SfLink link="#" target="_blank" class="sf-link--primary">
                {{ $t("Read recipes") }}
              </SfLink>
            </template>
            <MRecipeCarousel class="flex" />
          </OSection>
        </template>

        <MSeoBlock
            v-if="getCurrentBrand && !categoryMetaContent.isUrlInExclusionList && getSeoHtml"
            :seo-html="getSeoHtml"
        />
      </div>
    </ASmoothReflow>

    <no-ssr>
      <SidebarMobileFilters
          v-if="Object.keys(availableFilters).length > 0 && Object.keys(filterLabels).length > 0"
          :key="activeFiltersCount"
          :is-open="!!isFilterSidebarOpen"
          @close="isFilterSidebarOpen = false"
          @open="filterSidebarInner"
          @clear="clearFilters"
          :sort-label="sortLabel"
          :available-filters="availableFilters"
          :filters-count="activeFiltersCount"
          :filter-labels="filterLabels"
          :filter-actived="selectedFilters"
          :products-is-loading="productsIsLoading"
          :promo-count="getPromoCount"
          :is-promo-filter-active="true"
          @stock-filter-changed="stockFilterChanged"
          @promo-filter-changed="promoFilterChanged"
      />
    </no-ssr>

    <no-ssr>
      <OSidebarFiltersInner
          v-if="Object.keys(availableFilters).length > 0 && Object.keys(filterLabels).length > 0"
          :is-open="!!filterSidebarInnerOpen"
          :filter-name="filterSidebarInnerOpen"
          :filter-labels="filterLabels"
          @change="changeFilter"
          @change-sort-order="changeSortOder"
          @close="filterSidebarInnerOpen = ''"
          :sort-options="sortOptions"
          :current-filters="selectedFilters"
          :sort-order="sortOrder"
          :available-filters="availableFilters"
          :filters-count="activeFiltersCount"
          :aggregations="getAggregations"
          :products-is-loading="productsIsLoading"
          @submit-filter-price="submitFilterPrice"
      />
    </no-ssr>
    <JsonLdCategory :products-total="getCategoryProductsTotal" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import MBreadcrumbs from '../components/molecules/m-breadcrumbs';
import config from 'config';
import SpecialOffersProductsContent from 'theme/components/special-offers/products-content';
import OCategoriesFilter from 'theme/components/organisms/o-categories-filter';
import { htmlDecode } from '@vue-storefront/core/filters';
import { prepareCategoryProduct } from 'theme/helpers';
import { SfHeading, SfLink, SfSelect } from '@storefront-ui/vue';
import MSeoBlock from 'theme/components/molecules/m-seo-block';
import MRecentlyViewedProducts from 'theme/components/molecules/m-recently-viewed-products';
import MRecipeCarousel from 'theme/components/molecules/m-recipe-carousel';
import OSection from 'theme/components/organisms/o-section';
import MSidebarMobileButtons from 'theme/components/molecules/m-sidebar-mobile-buttons';
import DeviceType from 'theme/mixins/DeviceType';
import JsonLdCategory from 'theme/components/json-ld/json-ld-category';
import JsonLdBreadcrumb from 'theme/components/json-ld/json-ld-breadcrumb';
import { getMetaContent, metaCategory } from '../meta/brand';
import ASmoothReflow from '../components/atoms/a-smooth-reflow';
import intersect from '../directives/intersect';
import GoogleTagManager from 'theme/mixins/gtm';
import MAdvantages from 'theme/components/molecules/m-advantages.vue';
import NoSSR from 'vue-no-ssr'
import { isServer } from '@vue-storefront/core/helpers';
import MBrandPageBanners from 'theme/components/molecules/m-brand-page-banners.vue';
import MBrandLogo from 'theme/components/molecules/m-brand-logo.vue';
import MCategoryNP from 'theme/components/molecules/m-category-np.vue';

const THEME_PAGE_SIZE = 40;

const initData = async ({ store, route }) => {
  const { query } = route;

  try {
    let currentCategory;

    await store.dispatch('brand/loadBrandCategory', {
      route
    });

    if (query.cat) {
      currentCategory = await store.dispatch('brand/loadCategory', { route });
    } else {
      currentCategory = store.state.brand.currentBrand;
    }

    await store.dispatch('brand/setCurrentCategory', currentCategory);
    await Promise.all([
      await store.dispatch('category-extension/loadCategoryBreadcrumbs', {
        category: store.state['brand'].currentBrand,
        currentRouteName: store.state['brand'].currentBrand.name,
        omitCurrent: true
      }),
      await store.dispatch('brand/loadSampleProductsCategory', store.state['brand'].currentBrand.pim_brand_id),
      await store.dispatch('homepage/loadAdvantagesContent')
    ])
  } catch (e) {}
}

export default {
  name: 'Brand',
  components: {
    MCategoryNP,
    MBrandPageBanners,
    MCategoryFilterPromo: () => process.browser ? import('theme/components/molecules/m-category-filter-promo.vue') : null,
    MCategorySection: () => process.browser ? import('theme/components/molecules/m-category-section.vue') : null,
    MBrandLogo,
    MAdvantages,
    SpecialOffersSidebarFilter: () => process.browser ? import('theme/components/special-offers/sidebar-filter.vue') : null,
    JsonLdBreadcrumb,
    JsonLdCategory,
    MSelectedFilters: () => process.browser ? import('theme/components/molecules/m-selected-filters.vue') : null,
    MSeoBlock,
    MBreadcrumbs,
    SfLink,
    SfSelect,
    SfHeading,
    MRecentlyViewedProducts,
    MRecipeCarousel,
    OSection,
    MSidebarMobileButtons,
    SidebarMobileFilters: () => process.browser ? import('theme/components/special-offers/sidebar-mobile-filters.vue') : null,
    OSidebarFiltersInner: () => process.browser ? import('theme/components/organisms/o-sidebar-filters-inner.vue') : null,
    SpecialOffersProductsContent,
    ASmoothReflow,
    OCategoriesFilter,
    OPagination: () => process.browser ? import('theme/components/organisms/o-pagination') : null,
    'no-ssr': NoSSR,
    ALoadingSpinner: () => process.browser ? import('theme/components/atoms/a-loading-spinner.vue') : null,
    MCategoryFilterStock: () => process.browser ? import('theme/components/molecules/m-category-filter-stock.vue') : null
  },
  mixins: [
    DeviceType,
    GoogleTagManager
  ],
  directives: { intersect },
  data () {
    return {
      isLoadMoreMode: false,
      browserWidth: 0,
      isFilterSidebarOpen: false,
      filterSidebarInnerOpen: '',
      unsubscribeFromStoreAction: null,
      countRangePages: [],
      categoriesAreShown: false,
      isShowCategoryFilter: false,
      openNotice: true,
      is18plus: false,
      selectedFilters: {},
      sortOrder: 'popularity:desc',
      hasIntersected: false
    };
  },
  mounted () {
    if (Object.keys(this.getCurrentFilters).length) {
      this.updateSelectedFiltersFromStore(this.getCurrentFilters);
    }
    if (this.getCurrentSearchQuery.sort) {
      this.updateSortOrderFromStore(this.getCurrentSearchQuery.sort);
    }
  },
  async beforeRouteLeave (to, from, next) {
    await this.loadMore(false)
    next();
  },
  computed: {
    ...mapState({
      getProducts: state => state['brand'].products,
      getCurrentCategory: state => state['brand'].currentCategory,
      getAggregations: state => state['category-extension'].aggregations,
      categories: state => state['brand'].categories,
      getFiltersMap: state => state['brand'].filtersMap,
      getFilterPrice: state => state['category-extension'].filterPrice,
      getCurrentBrand: state => state['brand'].currentBrand,
      getAttributeListByCode: state => state['attribute'].list_by_code,
      getBreadcrumbsCurrent: state => state['breadcrumbs'].current,
      getBreadcrumbsRoutes: state => state['breadcrumbs'].routes,
      categoriesIsLoading: state => state['brand'].categoriesIsLoading,
      productsIsLoading: state => state['brand'].productsIsLoading,
      path: state => state['route'].path,
      from: state => state['route'].from,
      isAdditionalLoading: state => state['brand'].isAdditionalLoading,
      promotionBanners: state => state['brand'].promotionBanners,
      availableProducts: state => state['brand'].availableProducts
    }),
    ...mapGetters({
      getCurrentSearchQuery: 'brand/getCurrentSearchQuery',
      isCategoryProductsOutOfStock: 'category-extension/getIsCategoryProductsOutOfStock',
      getCategoryProductsTotal: 'brand/getCategoryProductsTotal',
      getCurrentFilters: 'brand/getCurrentFilters',
      getSystemFilterNames: 'category-next/getSystemFilterNames',
      getBannersMiddle: 'promoted/getBannersMiddle',
      getBannersTop: 'promoted/getBannersTop',
      shippingDetails: 'shipping/getShippingDetails',
      isLoadedShippingDetails: 'shipping/isLoadedShippingDetails',
      getDefaultShop: 'shipping/getDefaultShop'
    }),
    isLoading () {
      return this.categoriesIsLoading || this.productsIsLoading
    },
    isCategoryEmpty () {
      return this.getCategoryProductsTotal === 0;
    },
    breadcrumbs () {
      return this.getBreadcrumbsRoutes
        .map(route => ({
          text: htmlDecode(route.name),
          route: {
            link: route.route_link
          }
        }))
        .concat({
          text: htmlDecode(this.getBreadcrumbsCurrent)
        });
    },
    products () {
      return this.getProducts.map(prepareCategoryProduct);
    },
    cardInRow () {
      return this.isTablet ? 6 : 8
    },
    productsAfterInoBannerCount () {
      return THEME_PAGE_SIZE - this.cardInRow
    },
    sortOptions () {
      return Object.entries(config.products.sortByAttributes).map(attribute => {
        const [label, id] = attribute;
        return { id, label };
      });
    },
    sortLabel () {
      const selectedSortOrder =
          this.sortOptions.find(sortOption => sortOption.id === this.sortOrder) ||
          {};
      return selectedSortOrder.label || '';
    },
    availableFilters () {
      const filtersArray = this.getFiltersMap[this.getCurrentCategory?.id] || {};
      const { getSystemFilterNames } = this;

      return Object.entries(filtersArray)
        .filter(([filterType, filters]) => filters.length && !getSystemFilterNames.includes(filterType))
        .reduce((result, [filterType, filters]) => {
          result[filterType] = filters
            .filter(filter => filter.id !== '0')
          return result;
        }, { price: this.getPrice() });
    },
    filterLabels () {
      const attrArr = Object.values(this.getAttributeListByCode)
      const labels = {}
      attrArr.forEach(item => { labels[`${item.attribute_code}`] = item.frontend_label === undefined ? '' : item.frontend_label })
      return labels
    },
    activeFiltersCount () {
      let counter = 0;

      if (this.getCurrentSearchQuery.sort !== undefined && this.getCurrentSearchQuery.sort !== 'popularity:desc') {
        counter = 1;
      } else {
        counter = 0;
      }

      if (this.$route.params['has_promotion_in_stores'] !== undefined) {
        counter += 1;
      }

      Object.keys(this.selectedFilters).forEach(key => {
        counter += this.selectedFilters[key].length;
      });

      return counter;
    },
    getSeoHtml () {
      return this.getCurrentCategory?.description
    },
    categoryProductsTotal () {
      return this.$tc('{count} items', this.getCategoryProductsTotal)
    },
    categoryMetaContent () {
      return getMetaContent(this.getCurrentBrand)
    },
    totalPages () {
      return Math.ceil(this.getCategoryProductsTotal / THEME_PAGE_SIZE);
    },
    currentPage () {
      return Number(this.$route.query.page) || 1;
    },
    perPage () {
      return THEME_PAGE_SIZE;
    },
    getPromoCount () {
      if (!this.getAggregations.hasOwnProperty('agg_terms_has_promotion_in_stores')) {
        return 0
      }

      const buckets = this.getAggregations['agg_terms_has_promotion_in_stores']?.buckets || []

      const shopId = this.shippingDetails?.shopId || this.getDefaultShop?.id

      const item = buckets.find(i => +i.key === shopId)

      return item?.doc_count || 0
    }
  },
  watch: {
    'shippingDetails.shopId': {
      handler: async function (newValue, oldVal) {
        if (!newValue || newValue === oldVal) return

        this.$nextTick(async () => {
          await initData({ store: this.$store, route: this.$route })
          this.$store.dispatch('brand/loadBrandProducts', {
            route: this.$route,
            category: this.getCurrentCategory,
            pageSize: THEME_PAGE_SIZE
          })
          this.$store.dispatch('brand/loadBrandCategories')
        })
      }
    },
    getCurrentFilters: {
      handler (val, oldVal) {
        if (!Object.keys(oldVal).length && !Object.keys(this.selectedFilters).length) {
          this.updateSelectedFiltersFromStore(val);
          return
        }

        if (JSON.stringify(val) === JSON.stringify(oldVal)) {
          return
        }

        this.updateSelectedFiltersFromStore(val);
      },
      deep: true
    },
    'getCurrentSearchQuery.sort': {
      handler (val, oldVal) {
        if (!oldVal && !this.sortOrder) {
          this.updateSortOrderFromStore(val);
          return
        }

        if (val === oldVal) {
          return
        }

        this.updateSortOrderFromStore(val);
      }
    },
    isLoadedShippingDetails: {
      handler (val) {
        if (val && !this.shippingDetails.shopId) {
          this.$store.dispatch('brand/setProductsLoading', false)
          this.$store.dispatch('brand/loadBrandCategories', {
            route: this.$route
          })
          this.$store.dispatch('brand/loadBrandProducts', {
            route: this.$route,
            category: this.getCurrentCategory,
            pageSize: THEME_PAGE_SIZE
          })
        }
      }
    }
  },
  async asyncData ({ store, route }) {
    await initData({ store, route });
    await store.dispatch('brand/loadBrandProducts', {
      route: route,
      category: store.state.brand.currentCategory,
      pageSize: THEME_PAGE_SIZE
    });
    await store.dispatch('category/list', {
      parent: config.entities.category.categoriesRootCategoryId,
      level:
          config.entities.category.categoriesDynamicPrefetch &&
          config.entities.category.categoriesDynamicPrefetchLevel >= 0
            ? config.entities.category.categoriesDynamicPrefetchLevel
            : null,
      includeFields:
          config.entities.optimize && isServer
            ? config.entities.category.includeFields
            : null,
      excludeFields: [
        ...config.entities.category.excludeFields,
        'description',
        'brand_data'
      ],
      // excludeSlugs: ['brands'],
      includesInMenu: true
    })
    if (!isServer) {
      store.dispatch('brand/loadBrandCategories', {
        route: route
      })
    }
  },
  methods: {
    ...mapActions({
      changeRouterFilterParameters: 'brand/changeRouterFilterParameters',
      setProductsLoading: 'brand/setProductsLoading',
      loadMore: 'brand/loadMore'
    }),
    async setCurrentCategory (category) {
      await this.setProductsLoading(true);
      this.toggleCategoriesFilters();
      let filter = {}

      if (category) {
        filter = {
          id: Number(category.id)
        }
      }

      await this.loadMore(false);

      await this.updateAndRoute(filter?.id, 'cat', {});
    },
    changeSortOder (sortOrder) {
      this.loadMore(false);
      if (this.getCurrentSearchQuery.sort !== sortOrder) {
        this.setProductsLoading(true)
        this.sortOrder = sortOrder;
        const queryFilter = sortOrder === 'popularity:desc' ? '' : sortOrder;
        this.updateAndRoute(queryFilter, 'sort');
      }
    },
    async changeFilter (filter) {
      this.updateSelectedFilters(filter)
      await this.setProductsLoading(true);
      await this.loadMore(false);
      const { type, id } = filter;
      const updatedQuery = { ...this.$route.query };

      let queryFilter = updatedQuery[type] || [];

      if (Array.isArray(queryFilter)) {
        queryFilter = this.toggleFilter(queryFilter, id);
      } else {
        queryFilter = queryFilter === id ? '' : [queryFilter, id];
      }

      if (updatedQuery.page) {
        delete updatedQuery.page;
      }

      await this.updateAndRoute(queryFilter, type, updatedQuery);
    },
    toggleFilter (queryFilter, filterId) {
      return queryFilter.includes(filterId)
        ? queryFilter.filter(id => id !== filterId)
        : [...queryFilter, filterId];
    },
    async updateAndRoute (queryFilter, type, updatedQuery = { ...this.$route.query }) {
      if (!queryFilter || queryFilter.length === 0) {
        delete updatedQuery[type];
      } else {
        updatedQuery[type] = queryFilter;
      }

      await this.changeRouterFilterParameters(updatedQuery);
    },
    async clearFilters () {
      this.setProductsLoading(true)
      this.selectedFilters = {};
      await this.loadMore(false);
      const query = this.$route.query?.cat ? { cat: this.$route.query.cat } : {};
      await this.changeRouterFilterParameters(query);
    },
    clearPrice () {
      this.updateAndRoute('', 'price');
    },
    getFilterCount (filter) {
      const aggregations = [
        `agg_range_${filter.type}`,
        `agg_terms_${filter.type}`,
        `agg_terms_${filter.type}_options`
      ];

      return aggregations.reduce((result, aggregation) => {
        const buckets = this.getAggregations?.[aggregation]?.buckets;
        const bucket = buckets?.find(bucket => String(bucket.key) === String(filter.id));
        return result + (bucket?.doc_count || 0);
      }, 0);
    },
    filterSidebarInner (filter) {
      this.filterSidebarInnerOpen = filter
    },
    toggleCategoriesFilters () {
      this.categoriesAreShown = !this.categoriesAreShown
    },
    closeNoticeCategoryFn () {
      this.openNotice = false;
    },
    getPrice () {
      const { agg_price } = this.getAggregations;
      const price = {
        type: 'price',
        from: 0,
        to: 0
      };

      if (agg_price && Object.keys(agg_price).length > 0) {
        const { filter } = agg_price;
        price.from = filter['price_max']?.value ? Number(filter['price_max'].value).toFixed(0) : 0;
        price.to = filter['price_min']?.value ? Math.trunc(filter['price_min'].value) : 0;
      }

      return price;
    },
    submitFilterPrice (minPrice, maxPrice) {
      this.setProductsLoading(true)
      this.updateAndRoute(`${minPrice}-${maxPrice}`, 'price')
    },
    onIntersect (entry) {
      entry.isIntersecting &&
      !this.loading &&
      !this.loaded &&
      (this.hasIntersected = true)
    },
    updateSelectedFiltersFromStore (filters = this.getCurrentFilters) {
      this.selectedFilters = JSON.parse(JSON.stringify(filters));
    },
    updateSortOrderFromStore (sortOrder = this.getCurrentSearchQuery.sort) {
      this.sortOrder = sortOrder || 'popularity:desc';
    },
    updateSelectedFilters (filter) {
      const { type, id } = filter;

      if (!this.selectedFilters[type]) {
        this.$set(this.selectedFilters, type, [filter]);
        return;
      }

      if (this.selectedFilters[type]) {
        const isFilterActive = this.selectedFilters[type].find(item => item.id === id);
        let newFilters = [];

        if (isFilterActive) {
          newFilters = this.selectedFilters[type].filter(item => item.id !== id);
        } else {
          newFilters = [...this.selectedFilters[type], filter];
        }

        if (newFilters.length === 0) {
          delete this.selectedFilters[type];
        } else {
          this.selectedFilters[type] = newFilters;
        }
      }
    },
    onPaginationClick () {
      this.setProductsLoading(true)
    },
    stockFilterChanged ({ id, type }) {
      const currentStockShop = this.getCurrentSearchQuery.stock_shop;
      if (currentStockShop !== id) {
        this.setProductsLoading(true);
        const queryFilter = id === 'in_stock' ? id : '';
        this.updateAndRoute(queryFilter, type);
      }
    },
    async promoFilterChanged ({ type }) {
      const updatedQuery = { ...this.$route.query }

      if (updatedQuery.hasOwnProperty(type)) {
        delete updatedQuery[type];
      } else {
        updatedQuery[type] = null;
      }

      await this.changeRouterFilterParameters(updatedQuery);
    },
    onLoadMore () {
      this.loadMore(true);
    },
    onPageChanged () {
      this.loadMore(false);
    }
  },
  metaInfo: metaCategory
};
</script>

<style lang="scss" scoped>
@import "~theme/css/mixins";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.category {
  max-width: var(--max-width);
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
}

.category-footer {
  padding: 0;

  &__wrapper {
    position: relative;
  }

  @include for-desktop {
    padding: 0 var(--spacer-10);
  }

  .m-seo-block {
    padding: 0 var(--spacer-10) var(--spacer-100);

    @include for-tablet {
      padding-left: var(--spacer-17);
      padding-right: var(--spacer-18);
    }
  }

  .m-recently-viewed-products {
    ::v-deep {

      @include for-tablet {
        padding-top: var(--spacer-20);
      }
      .o-section {
        padding: 0;

        &--center {
          padding: 0;
        }

        &__heading {
          @include for-mobile {
            padding: 0 var(--spacer-10) var(--spacer-15) var(--spacer-10);
          }

          @include for-tablet {
            padding: 0 var(--spacer-16) var(--spacer-15) 0;
          }
        }
      }

      @media (min-width: $tablet-min) {
        .sf-carousel__slides {
          padding-bottom: var(--spacer-22);
        }
      }

      .glide__slide .sf-product-card {
        padding: 0;
      }
    }

    @include for-tablet {
      margin-left: var(--spacer-16);
    }
  }
}

.main {
  display: flex;
  flex-direction: column;

  @include for-desktop {
    flex-direction: row;
  }
  &.section {
    padding: 0 var(--spacer-10) var(--spacer-50);

    @include for-tablet {
      padding-left: var(--spacer-17);
      padding-right: var(--spacer-18);
    }
  }
}
.breadcrumbs {
  padding: var(--spacer-base) var(--spacer-base) var(--spacer-base) var(--spacer-sm);
}

.navbar {
  position: relative;
  display: flex;

  ::v-deep .sf-heading__title {
    @include for-mobile {
      --heading-title-color: --black;
      --heading-title-font-size: var(--font-size-20);
      line-height: 1.2;
      text-align: left;
    }

    @include for-tablet {
      --heading-title-font-size: var(--font-size-32);
    }
  }

  &.section {
    padding: 0 var(--spacer-10);
    margin-bottom: var(--spacer-10);
    min-height: px2rem(24.5);

    @media (min-width: $tablet-min) {
      padding-left: var(--spacer-16);
      padding-right: var(--spacer-16);
      margin-bottom: var(--spacer-sm);
      min-height: px2rem(38.5);
    }
  }

  &__aside {
    align-items: center;
    display: flex;
    flex: 0 0 15%;
    padding: var(--spacer-sm) var(--spacer-sm);
    border: solid var(--c-light);
    border-width: 0 1px 0 0;
  }

  &__main {
    .sf-heading {
      --heading-padding: 0 var(--spacer-20) 0 0;
    }
  }

  &__filters-button {
    display: flex;
    align-items: center;
    font-size: 1rem;
    grid-column: 1;
    justify-self: start;

    @media (min-width: $tablet-min) {
      &:hover {
        .sf-icon {
          fill: var(--c-primary);
        }
      }
    }

    @include for-mobile {
      --button-text-transform: uppercase;
      font-size: var(--font-xs);

      &.sort-by__button {
        order: 1;
      }
    }
  }

  &__filter {
    display: flex;
    grid-area: filter;
  }

  &__filters-icon {
    margin: 0 var(--spacer-sm) 0 0;
  }

  &__label {
    font-family: var(--font-family-secondary);
    font-weight: var(--font-normal);
    color: var(--dark-gray);
    margin: 0 var(--spacer-2xs) 0 0;
    font-size: var(--font-size-13);
    white-space: nowrap;

    transition: opacity 200ms;
    opacity: 1;

    &--hide {
      opacity: 0;
    }
  }

  &__select {
    --select-padding: 0 var(--spacer-lg) 0 var(--spacer-2xs);
    --select-margin: 0;
    --select-selected-padding: 0 var(--spacer-xl) 0 0;

    .dropdown-chevron {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  &__sort {
    display: flex;
    align-items: center;
    justify-self: end;
    grid-area: sort;

    .sf-select-option--is-active {
      color: var(--c-primary);
      pointer-events: none;
    }

    @include for-desktop {
      justify-self: end;
    }
  }

  &__counter {
    display: inline;
    font-family: var(--font-family-secondary);
  }

  &__view {
    display: flex;
    align-items: center;
    margin: 0 var(--spacer-xl);

    @include for-desktop {
      margin: 0 0 0 var(--spacer-2xl);
    }

    &-icon {
      cursor: pointer;
    }

    &-label {
      margin: 0 var(--spacer-sm) 0 0;
      font: var(--font-medium) var(--font-xs) / 1.6 var(--font-family-secondary);
      text-decoration: underline;
    }
  }

  &__title {
    display: inline;

    ::v-deep .sf-heading__title {
      padding: 0;
      display: inline;
      @include header-title;
    }
  }
}

.sort-by {
  --select-dropdown-z-index: 3;
  flex: unset;
  padding: 0;

  ::v-deep {
    .sf-select__dropdown {
      left: initial;
      min-width: max-content;
      right: 0;
    }

    .sf-select-option {
      cursor: pointer;
    }
  }

  // This is needed to hide SfSelect main element on mobile view.
  // This code can be removed when SfSelect supports better customization.
  @include for-mobile {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    --select-dropdown-z-index: 2;

    ::v-deep .sf-select__cancel {
      margin: 16px;
      box-shadow: 4px 12px 24px rgba(119, 121, 122, 0.25);
      --button-width: calc(100% - 32px);
    }
  }
}

.sidebar {
  flex: 100%;

  @include for-desktop {
    padding: 0;
    flex: 0 0 13.75rem;
    border: none;
    margin-right: var(--spacer-20);
    max-width: 13.75rem;
  }

  &-filters {
    background: var(--light-gray);
    border-radius: 10px;
    padding: var(--spacer-sm) var(--spacer-20);
    margin-top: var(--spacer-10);
    --sidebar-title-display: none;
    --sidebar-top-padding: 0;

    @include for-desktop {
      --sidebar-content-padding: 0 var(--spacer-xl);
      --sidebar-bottom-padding: 0 var(--spacer-xl);
    }
  }

  &-hide {
    display: none!important;

    &--mobile {
      display: none;
    }
  }
}

.sidebar-filters {
  position: relative;
  --sidebar-title-display: none;
  --sidebar-top-padding: 0;

  @include for-desktop {
    --sidebar-content-padding: 0 var(--spacer-xl);
    --sidebar-bottom-padding: 0 var(--spacer-xl);
    padding: 0 var(--spacer-20);
    max-width: px2rem(220);
  }
}

.list {
  --menu-item-font-size: var(--font-sm);

  &__item {
    &:not(:last-of-type) {
      --list-item-margin: 0 0 var(--spacer-sm) 0;
    }
  }
}

.products {
  box-sizing: border-box;
  flex: 1;
  margin: 0;
  overflow: hidden;

  &__navbar-container {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-zircon);
    padding-bottom: var(--spacer-10);
    margin-bottom: var(--spacer-10);
  }

  ::v-deep &__grid {
    display: flex;
    flex-wrap: wrap;
  }

  ::v-deep &__grid {
    @include for-desktop {
      width: 100%;

      ::v-deep .sf-product-card {
        &.sf-product-card--out-of-stock-container:hover:before {
          bottom: calc(-1 * var(--spacer-5));
        }
        &:hover {
          &:before {
            bottom: calc(-1 * var(--spacer-35));
          }
          .sf-product-card__hidden-basket {
            position: absolute;
          }
        }
        .sf-product-card__hidden-basket {
          position: absolute;
          left:5px;
          bottom: -15px;
        }
      }
    }

    &.top-grid {
      padding-top: var(--spacer-10);
    }
  }

  &__slide-enter {
    opacity: 0;
    transform: scale(0.5);
  }

  &__slide-enter-active {
    transition: all 0.2s ease;
    transition-delay: calc(0.1s * var(--index));
  }

  &__load-more {
    background-color: transparent;
    font-weight: 400;
    color: var(--orange);
    transition: color 0.3s ease-in-out;

    @media (min-width: $tablet-min) {
      &:hover {
        color: var(--orange-hover);
      }
    }
  }

  @include for-desktop {
    margin: 0;

    &__pagination {
      display: flex;
      justify-content: center;
      margin: 0;
      background-color: var(--light-gray);
      padding: var(--spacer-20);
    }

    &__product-card {
      flex: 1 1 25%;
    }

    &__list {
      margin: 0 0 0 var(--spacer-sm);
    }
  }

  &__not-found {
    margin-top: var(--spacer-30);
    --heading-text-align: left;

    ::v-deep .sf-heading__title {
      color: var(--dark-gray);
      margin-bottom: var(--spacer-10);
      font-size: var(--font-size-16);
      line-height: var(--font-size-19);

      @include for-desktop {
        margin-bottom: var(--spacer-18);
        font-size: var(--font-size-24);
        line-height: var(--font-size-25);
      }
    }

    ::v-deep .sf-heading__subtitle {
      color: var(--black);
      font-size: var(--font-size-14);
      line-height: var(--font-size-17);

      @include for-desktop {
        font-size: var(--font-size-16);
        line-height: var(--font-size-19);
      }
    }
  }

  &__change-city {
    margin-top: var(--spacer-20);
    width: px2rem(137);
    height: var(--spacer-40);

    @include for-desktop {
      font-size: var(--font-size-18);
      margin-top: var(--spacer-30);
      width: px2rem(228);
      height: var(--spacer-56);
    }

    @media (max-width: $mobile-max) {
      padding: var(--spacer-11) var(--spacer-sm);
      font-size: var(--font-size-14);
      line-height: var(--font-size-24);
    }
  }
}

.bottom-plp-block {
  margin-left: calc(-1 * var(--spacer-10));
  margin-right: calc(-1 * var(--spacer-10));
  width: calc(100% + calc(2 * var(--spacer-10)));

  @media (min-width: $tablet-min) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.filters {
  &__title {
    --heading-title-font-size: var(--font-xl);
    margin: var(--spacer-xl) 0 var(--spacer-base) 0;

    &:first-child {
      margin: calc(var(--spacer-xl) + var(--spacer-base)) 0 var(--spacer-xs) 0;
    }
  }

  &__color {
    margin: var(--spacer-xs) var(--spacer-xs) var(--spacer-xs) 0;
    border: 1px solid var(--c-light);
  }

  &__item {
    --filter-label-color: var(--c-secondary-variant);
    --filter-count-color: var(--c-secondary-variant);
    --checkbox-padding: 0 var(--spacer-sm) 0 var(--spacer-xl);
    padding: var(--spacer-sm) 0;
    border-bottom: 1px solid var(--c-light);

    &:last-child {
      border-bottom: 0;
    }

    @include for-desktop {
      --checkbox-padding: 0;
      margin: var(--spacer-sm) 0;
      border: 0;
      padding: 0;
    }
  }

  &__accordion-item {
    --accordion-item-content-padding: 0;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    width: 100vw;
  }

  &__buttons {
    margin: var(--spacer-sm) 0;
  }

  &__button-clear {
    --button-background: var(--c-light);
    --button-color: var(--c-dark-variant);
    margin: var(--spacer-xs) 0 0 0;
  }
}

.center {
  padding: 0 var(--spacer-sm);
  box-sizing: border-box;
  @include for-desktop {
    max-width: var(--max-width);
    margin: 0 auto;
  }
}

.banner-info-wrap {
  margin: px2rem(10) 0 px2rem(20);
  position: relative;
  transform: none;

  &.banner-missed {
    .slider-carousel {
      display: none;
    }
  }

  &--last {
    margin-bottom: px2rem(50);

    &.banner-missed {
      margin: calc(-1 * var(--spacer-30)) 0 var(--spacer-30);
      border-top: 1px solid var(--color-zircon);
      position: relative;
      z-index: -1;
    }

    ::v-deep {
      .glide__track {
        padding-top: 0;
      }
    }
  }

  ::v-deep {
    .sf-banner-plp {
      background-position: right;

      @media (max-width: $mobile-max) {
        min-height: px2rem(115);
        padding: 0 var(--spacer-25) var(--spacer-20) ;
      }

      @media (min-width: $tablet-min) {
        min-height: px2rem(115);
        padding: 0 var(--spacer-30) var(--spacer-25) ;
      }

      @include for-desktop {
        min-height: px2rem(150);
        padding: 0 var(--spacer-56) var(--spacer-25) ;
      }

      &__description {
        font-size: var(--font-size-14);
        line-height: var(--font-size-17);

        @media (min-width: $tablet-min) {
          font-size: var(--font-size-24);
          line-height: var(--font-size-25)
        }
      }
    }

    .sf-banner__container {
      padding-right: var(--spacer-50);
      flex: 1 1 var(--banner-container-width, 100%);
    }
  }

  @include for-tablet {
    transform: none;
  }

  @include for-desktop {
    margin-top: 0;
  }

  &:empty {
    margin: 0;
  }
}

.m-filter-categories {
  ::v-deep {
    .sf-accordion.accordion {
      max-height: 21.25rem;
      overflow-y: scroll;
      border-radius: var(--spacer-10);
      @include for-desktop {
        .accordion-item {
          &__header:hover {
            color: var(--orange);
          }
        }
      }
    }
  }
}

.category--brand {
    ::v-deep .m-sidebar-mobile-filters__item--promo {
      display: none;
    }

    ::v-deep .m-filter-categories {
      .accordion-item {
        @include for-desktop {
          width: 75%;
        }
      }
      .sf-accordion.accordion {
        @include for-desktop {
          max-height: 100%;
        }
      }
    }

  --section-heading-margin: 0 0 var(--spacer-15) 0;
  --section-heading-padding: 0 0 0 var(--spacer-10);

  @media screen and (min-width: $desktop-min) {
    --section-heading-margin: 0 0 var(--spacer-15) 0;
    --section-heading-padding: 0;
  }

  .category__np {
    margin: 0 var(--spacer-10) var(--spacer-15) var(--spacer-10);
  }
}

</style>

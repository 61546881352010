<template>
  <SfAccordionItem
    ref="accordionItem"
    :header="header"
  >
    <template #header="{ header }">
      <div
        class="accordion-item__header"
        :class="{
          'accordion-item__header--active': active,
          'accordion-item__first-level': isFirstLevel
        }"
        data-transaction-name="Filter Category Item"
        @click="$emit('select')"
      >
        <span class="accordion-item__label">
          {{ header }}
          <span class="accordion-item__label-count">
            {{ count || '' }}
          </span>
        </span>
      </div>
    </template>
    <slot name="nested" />
  </SfAccordionItem>
</template>

<script>
export default {
  name: 'AFilterCategoryItem',
  props: {
    header: { type: String, required: true },
    count: { type: Number, required: true },
    active: { type: Boolean, default: false },
    isFirstLevel: { type: Boolean, default: false }
  },
  data () {
    return {
      isOpen: false
    }
  },
  watch: {
    isOpen (value) {
      this.$refs.accordionItem.isOpen = value;
    }
  }
}
</script>

<template>
  <SfAccordion
    :show-chevron="false"
    :class="isSubItem ? 'accordion--sub' : 'accordion'"
    :open="openedCategories"
  >
    <AFilterCategoryItem
      v-if="isShowAllVisible"
      :count="totalProducts"
      :header="showAllTitle !== '' ? showAllTitle : $t('All goods')"
      :active="activeCategoryId === null"
      class="accordion-item"
      @select="$emit('select', null)"
    />

    <AFilterCategoryItem
      v-for="item in items"
      :key="item.id"
      :count="getProductsCount(item)"
      :header="item.name"
      :active="isActiveCategory(item)"
      :class="accordionItemClass"
      :is-first-level="level === firstLevel"
      @select="$emit('select', item)"
    >
      <template v-if="!showOnlyFirstLevel && item.items && item.items.length > 0" #nested>
        <AFilterCategoryItems
          :level="level + 1"
          :items="item.items"
          @select="$emit('select', $event)"
        />
      </template>
    </AFilterCategoryItem>
  </SfAccordion>
</template>

<script>
import { SfAccordion } from '@storefront-ui/vue';
import AFilterCategoryItem from 'theme/components/atoms/a-filter-category-item';

const FIRST_LEVEL = 1;

export default {
  name: 'AFilterCategoryItems',
  components: {
    AFilterCategoryItem,
    SfAccordion
  },
  props: {
    items: { type: Array, required: true },
    level: { type: Number, required: true },
    showAllGoods: { type: Boolean, default: false },
    totalProducts: { type: Number, default: 0 },
    showAllTitle: { type: String, default: '' },
    showOnlyFirstLevel: { type: Boolean, default: false }
  },
  inject: ['provided'],
  computed: {
    isSubItem () {
      return this.level > FIRST_LEVEL;
    },
    firstLevel () {
      return FIRST_LEVEL;
    },
    isShowAllVisible () {
      return this.level === FIRST_LEVEL && this.showAllGoods;
    },
    accordionClass () {
      return this.isSubItem
        ? 'accordion--sub'
        : 'accordion';
    },
    accordionItemClass () {
      return this.isSubItem
        ? 'accordion-item--sub'
        : 'accordion-item';
    },
    activeCategoryId () {
      return this.provided?.activeCategoryId || null;
    },
    openedCategories () {
      return this.provided?.openedCategories || [];
    }
  },
  methods: {
    getProductsCount (category) {
      return category.countProduct || 0;
    },
    isActiveCategory (item) {
      return this.activeCategoryId === item.id
    }
  }
}
</script>

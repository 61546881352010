<template>
  <div class="m-filter-categories">
    <div
      v-if="isLoading"
      class="m-filter-categories-placeholder"
    >
      <div
        v-for="index in placeholderCount"
        :key="index"
        class="m-filter-categories-placeholder__title placeholder-item-svg--css-animation"
      />
    </div>
    <AFilterCategoryItems
      v-else-if="!isLoading && show"
      :items="preparedCategories"
      :level="1"
      :show-all-goods="true"
      @select="categorySelectHandler"
    />
  </div>
</template>

<script>
import { prepareCategoryMenuItem } from 'theme/helpers';
import DeviceType from 'theme/mixins/DeviceType';
import AFilterCategoryItems from 'theme/components/atoms/a-filter-category-items';
import { mapGetters } from 'vuex';
import { isServer } from '@vue-storefront/core/helpers';

export default {
  name: 'OCategoriesFilter',
  components: {
    AFilterCategoryItems
  },
  mixins: [DeviceType],
  props: {
    currentCategory: { type: Object, default: null },
    categories: { type: Array, required: true },
    show: { type: Boolean, required: false, default: true },
    isLoading: { type: Boolean, default: true }
  },
  data () {
    return {
      openedCategories: [],
      currentFilterCategories: [],
      perfectCategory: true,
      placeholderCount: 10,
      timer: null
    }
  },
  provide () {
    const provided = {};

    // Reactive injection
    Object.defineProperties(provided, {
      productsCount: {
        get: () => this.categoriesProductsCount
      },
      activeCategoryId: {
        get: () => this.currentFilterCategories?.id || null
      },
      openedCategories: {
        get: () => this.openedCategories
      },
      perfectCategory: {
        get: () => this.perfectCategory
      }
    })

    return { provided };
  },
  computed: {
    ...mapGetters({
      categoriesProductsCount: 'category-extension/getCategoriesProductsCount',
      getCurrentCategory: 'category-next/getCurrentCategory'
    }),
    preparedCategories () {
      return this.prepareCategories(this.rootCategories);
    },
    rootCategories () {
      return this.categories.filter(category => category.level === 2 && category.slug !== 'rasprodazha' && category.parent_id !== 470 && category.id !== 470)
    },
    parentIdGroups () {
      const groups = {};

      for (const category of this.categories) {
        if (groups[category.parent_id] === undefined) {
          groups[category.parent_id] = [];
        }

        groups[category.parent_id].push(category);
      }

      return groups;
    }
  },
  methods: {
    autoScrollCategory () {
      if (isServer) return
      this.autoScrollCategoryInterval = setInterval(() => {
        const activeLink = document.querySelector('.m-filter-categories .accordion-item__header--active');
        if (activeLink !== null) {
          const containerOffsetTop = document.querySelector('.m-filter-categories .sf-accordion').getBoundingClientRect().top;
          const elementOffsetTop = activeLink.getBoundingClientRect().top;
          const offsetTop = elementOffsetTop - containerOffsetTop;
          document.querySelector('.m-filter-categories .sf-accordion').scrollTo({
            top: offsetTop,
            left: 0,
            behavior: 'smooth'
          });
          clearInterval(this.autoScrollCategoryInterval);
        }
      }, 200);
    },
    prepareCategories (categories) {
      const prepareCategory = (category) => {
        const preparedCategory = prepareCategoryMenuItem(category);
        const childCategories = this.parentIdGroups[preparedCategory.id];
        if (childCategories) {
          preparedCategory.items = this.prepareCategories(childCategories);
        }
        return preparedCategory;
      };

      return categories
        .map(prepareCategory)
        .sort((a, b) => a.position - b.position);
    },
    categorySelectHandler (category) {
      this.currentFilterCategories = category;
      this.openedCategories = category
        ? this.getCategoryNamesFromPath(category.path)
        : [];
      this.$emit('select', category);
    },
    getCategoryNamesFromPath (path) {
      const categoryIds = path.split('/');

      return this.categories
        .filter((category) => categoryIds.includes(String(category.id)))
        .map((category) => category.name);
    },
    setSelectedCategory () {
      if (!this.categories.length) return;

      if (this.$route.query.hasOwnProperty('cat')) {
        const categoryIds = Array.isArray(this.$route.query.cat)
          ? this.$route.query.cat.map(Number)
          : [Number(this.$route.query.cat)];
        const categories = this.categories.filter((category) =>
          categoryIds.includes(category.id)
        );

        const openedCategoryArray = [];

        const buildOpenedCategoriesTree = (categoryId) => {
          const category = this.categories.find((item) => item.id === categoryId);
          openedCategoryArray.push(category.name);
          if (category.parent_id !== 2) {
            buildOpenedCategoriesTree(category.parent_id);
          }
        };

        buildOpenedCategoriesTree(categories[0].id);

        this.currentFilterCategories = categories[0];
        this.openedCategories = openedCategoryArray.reverse();
      }
    }
  },
  watch: {
    isLoading: {
      handler (val, oldVal) {
        if (val || val === oldVal) return;
        this.setSelectedCategory();
      }
    },
    show (prev) {
      if (!prev) return
      this.$nextTick(() => {
        this.autoScrollCategory()
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/px2rem";

.m-filter-categories {
  overflow: auto;
  padding: 0;
  margin: 0 0 18px;

  @include for-desktop {
    background: var(--accordion-background);
    border-radius: var(--spacer-10);
  }

  @media only screen and (min-width: $tablet-min) {
    margin: 0 0 10px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @-moz-document url-prefix() {
    scrollbar-width: none;
  }

  ::v-deep .accordion {
    font-family: Inter,serif;
    display: block;
    width: auto;
    align-items: center;
    padding-right: var(--spacer-5);
    padding-left: var(--spacer-20);
    padding-bottom:  var(--spacer-20);
    background: var(--accordion-background);
    border-radius: var(--spacer-10);
    scrollbar-width: thin;
    scrollbar-color:  rgba(235, 103, 71, 0.3);
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: var(--orange);
      }
    }

    @include for-desktop {
      padding-right: 0;
      padding-left: 0;
      background: transparent;
      border-radius: 0;
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(235, 103, 71, 0.3);
    }

    @include for-mobile {
      > .accordion-item {
        .accordion-item__first-level:before {
          position: absolute;
          left: -5px;
          bottom: 0;
          top: 0;
          right: 1px;
          background: var(--pale-orange2);
          content: ' '
        }
      }
    }

    &-item {
      &:first-child {
        @include for-desktop {
          margin-left: var(--spacer-20);
        }
      }

      &:not(:first-child) {

        @include for-desktop {
          margin-left: var(--spacer-20);
          margin-right: var(--spacer-20);
        }
      }

      &__header {
        color: var(--black);
        white-space: normal;
        padding: var(--spacer-20) 0 0;
        font-style: normal;
        font-weight: normal;
        font-size: var(--font-sm);
        display: inline-block;
        border-radius: var(--spacer-10);
        position: relative;
        width: 80%;
        line-height: var(--font-lg);
        transition: color 0.3s ease-in-out;
        cursor: pointer;

        .accordion-item__label {
          position: relative;

          &-count {
            color: var(--neutral-gray);
            padding-left: var(--spacer-10);
            position: absolute;
            left: 100%;
            bottom: 0;
            transition: color .3s ease-in-out;
          }
        }

        &--active {
          color: var(--orange);
          background-color: inherit;

          .accordion-item__label {

            &-count {
              color: var(--orange);
              transition: color .3s ease-in-out;
            }
          }
        }

        @include for-desktop {
          &:hover {
            .accordion-item__label {

              &-count {
                color: var(--orange);
              }
            }
          }
        }
      }

      .sf-accordion-item__content {
        padding-bottom: 0;
        padding-top: 0;
        border: 0 solid var(--gray);

        & > .accordion--sub {
          padding-left: var(--spacer-20);
          position: relative;
          &:before {
            content: '';
            position: absolute;
            top: 1px;
            left: 0;
            height: calc(100% + var(--spacer-17));
            width: 1px;
            background: var(--category-divider-color);
          }

          .accordion-item__first-level + .sf-accordion-item__content {
            border-left: none;

            @include for-desktop {
              position: relative;

              .sf-accordion-item:first-child a {
                padding-top: var(--spacer-20);
              }

              .sf-accordion-item:last-child a {
                padding-bottom: 0;
              }

              .accordion-item__header.is-open {
                border-radius: 0;
              }
            }
          }
        }
      }
    }
  }

  &-placeholder {
    background: var(--accordion-background);
    border-radius: var(--spacer-10);
    padding: var(--spacer-10) var(--spacer-20) var(--spacer-30);

    &__title {
      margin-top: var(--spacer-20);
      background: var(--placeholder-color);
      border-radius: var(--spacer-3);
      height: var(--spacer-20);
      width: 100%;
    }
  }
}
</style>

<template>
  <div class="block">
    <template v-if="!isLoading && isProductsNotFound">
      <SfHeading
          class="products__not-found"
          :title="isProductNone ? $t('No products found in the catalog') : $t('No products found!')"
          :subtitle="isProductNone ? $t('Try using a different combination of filters') : $t('Please select another city or another category')"
      />
      <SfButton
          class="products__change-city"
          v-if="!isProductNone"
          data-transaction-name="Category - Change City"
          @click="openLocateModal"
      >
        {{ $t('Change city') }}
      </SfButton>
    </template>
    <template v-else>
      <div
          class="banner-info-wrap"
          v-if="productsBeforeInoBanner.length > 0 && !isLoading"
          :class="{'banner-missed': !hasBannerTop}"
      >
        <MBannerCarousel
          v-if="isBannerShown && !isLoading && hasBannerTop"
          :banners="getBannersTop"
          :is-responsive="true"
        />
      </div>
      <SpecialOffersProductsList
          :products="productsBeforeInoBanner"
          class-list="products__grid top-grid"
          badge-label="-50%"
          badge-color="color-primary"
          :is-loading="isLoading"
          :alt-name="altName"
      />
      <div
          class="banner-info-wrap banner-info-wrap--last"
          v-if="productsAfterInoBanner.length > 0 && !isLoading"
          :class="{'banner-missed': !hasBannerMiddle}"
      >
        <MBannerCarousel
          v-if="isBannerShown"
          :banners="getBannersMiddle"
          :is-responsive="true"
        />
      </div>
      <SpecialOffersProductsList
          :products="productsAfterInoBanner"
          class-list="products__grid main-grid"
          badge-label="-50%"
          badge-color="color-primary"
          :last-row-class="hasBannerMiddle"
          :is-loading="isLoading"
          :alt-name="altName"
      />
    </template>
  </div>
</template>
<script>
import MBannerCarousel from 'theme/components/molecules/m-banner-carousel.vue';
import { SfButton, SfHeading } from '@storefront-ui/vue';
import { ModalList } from 'theme/store/ui/modals';
import DeviceType from 'theme/mixins/DeviceType';
import SpecialOffersProductsList from 'theme/components/special-offers/products-list.vue';

export default {
  name: 'SpecialOffersProductsContent',
  components: {
    MBannerCarousel,
    SfButton,
    SfHeading,
    SpecialOffersProductsList
  },
  mixins: [
    DeviceType
  ],
  props: {
    altName: {
      type: String,
      default: ''
    },
    products: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: true
    },
    banners: {
      type: Array,
      default: () => []
    },
    isBannerShown: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getBannersTop () {
      return this.banners.filter(e => e.position === '0');
    },
    getBannersMiddle () {
      return this.banners.filter(e => e.position === '1');
    },
    cardInRow () {
      return this.isTablet ? 6 : 8
    },
    productsBeforeInoBanner () {
      return this.products.slice(0, this.cardInRow);
    },
    productsAfterInoBanner () {
      return this.products.slice(this.cardInRow, this.products.length);
    },
    isProductsNotFound () {
      return this.productsBeforeInoBanner.length === 0
    },
    isProductNone () {
      return this.products.length === 0;
    },
    hasBannerTop () {
      return !!this.getBannersTop.length
    },
    hasBannerMiddle () {
      return !!this.getBannersMiddle.length
    }
  },
  methods: {
    openLocateModal () {
      this.openModal({ name: ModalList.ShippingModal });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/mixins";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.products {
  box-sizing: border-box;
  flex: 1;
  margin: 0;
  overflow: hidden;

  &__navbar-container {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-zircon);
    padding-bottom: var(--spacer-10);
    margin-bottom: var(--spacer-10);
  }

  ::v-deep &__grid {
    display: flex;
    flex-wrap: wrap;
  }

  ::v-deep &__grid {
    @include for-desktop {
      width: 100%;

      ::v-deep .sf-product-card {
        &.sf-product-card--out-of-stock-container:hover:before {
          bottom: calc(-1 * var(--spacer-5));
        }
        &:hover {
          &:before {
            bottom: calc(-1 * var(--spacer-35));
          }
          .sf-product-card__hidden-basket {
            position: absolute;
          }
        }
        .sf-product-card__hidden-basket {
          position: absolute;
          left:5px;
          bottom: -15px;
        }
      }
    }

    &.top-grid {
      padding-top: var(--spacer-10);
    }
  }

  &__slide-enter {
    opacity: 0;
    transform: scale(0.5);
  }

  &__slide-enter-active {
    transition: all 0.2s ease;
    transition-delay: calc(0.1s * var(--index));
  }

  &__load-more {
    background-color: transparent;
    font-weight: 400;
    color: var(--orange);
    transition: color 0.3s ease-in-out;

    &:hover {
      color: var(--orange-hover);
    }
  }

  @include for-desktop {
    margin: 0;

    &__pagination {
      display: flex;
      justify-content: center;
      margin: 0;
      background-color: var(--light-gray);
      padding: var(--spacer-20);
    }

    &__product-card {
      flex: 1 1 25%;
    }

    &__list {
      margin: 0 0 0 var(--spacer-sm);
    }
  }

  &__not-found {
    margin-top: var(--spacer-30);
    --heading-text-align: left;

    ::v-deep .sf-heading__title {
      color: var(--dark-gray);
      margin-bottom: var(--spacer-10);
      font-size: var(--font-size-16);
      line-height: var(--font-size-19);

      @include for-desktop {
        margin-bottom: var(--spacer-18);
        font-size: var(--font-size-24);
        line-height: var(--font-size-25);
      }
    }

    ::v-deep .sf-heading__subtitle {
      color: var(--black);
      font-size: var(--font-size-14);
      line-height: var(--font-size-17);

      @include for-desktop {
        font-size: var(--font-size-16);
        line-height: var(--font-size-19);
      }
    }
  }

  &__change-city {
    margin-top: var(--spacer-20);
    width: px2rem(137);
    height: var(--spacer-40);

    @include for-desktop {
      font-size: var(--font-size-18);
      margin-top: var(--spacer-30);
      width: px2rem(228);
      height: var(--spacer-56);
    }

    @media (max-width: $mobile-max) {
      padding: var(--spacer-11) var(--spacer-sm);
      font-size: var(--font-size-14);
      line-height: var(--font-size-24);
    }
  }
}

.banner-info-wrap {
  margin: px2rem(10) 0 px2rem(20);
  position: relative;
  transform: none;

  &.banner-missed {
    .slider-carousel {
      display: none;
    }
  }

  &--last {
    margin-bottom: px2rem(50);

    &.banner-missed {
      margin: calc(-1 * var(--spacer-30)) 0 var(--spacer-30);
      border-top: 1px solid var(--color-zircon);
      position: relative;
      z-index: -1;
    }

    ::v-deep {
      .glide__track {
        padding-top: 0;
      }
    }
  }

  ::v-deep {
    .sf-banner-plp {
      background-position: right;

      @media (max-width: $mobile-max) {
        min-height: px2rem(115);
        padding: 0 var(--spacer-25) var(--spacer-20) ;
      }

      @media (min-width: $tablet-min) {
        min-height: px2rem(115);
        padding: 0 var(--spacer-30) var(--spacer-25) ;
      }

      @media (min-width: $desktop-min) {
        min-height: px2rem(150);
        padding: 0 var(--spacer-56) var(--spacer-25) ;
      }

      &__description {
        font-size: var(--font-size-14);
        line-height: var(--font-size-17);

        @media (min-width: $tablet-min) {
          font-size: var(--font-size-24);
          line-height: var(--font-size-25)
        }
      }
    }

    .sf-banner__container {
      padding-right: var(--spacer-50);
      flex: 1 1 var(--banner-container-width, 100%);
    }
  }

  @media (min-width: $tablet-min) {
    transform: none;
  }

  @media (min-width: $desktop-min) {
    margin-top: 0;
  }

  &:empty {
    margin: 0;
  }
}
</style>

<template>
  <lazy-hydrate :trigger-hydration="isMounted">
    <SfHero
      class="m-brend-banners hero"
      :slider-options="heroSettings"
      v-if="preparedHeroes.length"
    >
      <SfOHeroItem
        v-for="(hero, i) in preparedHeroes"
        :key="i"
        :title="hero.title || ''"
        :title-show="+hero.show_title || 0"
        :subtitle="hero.subtitle || ''"
        :sub-title-show="+hero.show_subtitle || 0"
        :subtitle-color="hero.subtitle_color || ''"
        :button-show="+hero.button_show || 0"
        :image-mobile="$mq !== 'xsm' ? hero.image.desktop : hero.image.mobile"
        :image-desktop="hero.image.desktop"
        :image="hero.image"
        :background="hero.background_color || ''"
      >
        <template #title>
          <div
            v-if="hero.title"
            class="sf-hero-item__title"
            :style="{ color: hero.title_color }"
          >
            {{ hero.title }}
          </div>
        </template>
        <template #call-to-action>
          <SfLink
            :link="hero.link"
            :target="hero.target"
          >
            <SfButton
              :style="{
                color: hero.button_text_color,
                backgroundColor: hero.button_color
              }"
              class="sf-hero__banner-button sf-button--mq"
            >
              {{ hero.button_text }}
            </SfButton>
          </SfLink>
        </template>
        <template
          #big-link
          v-if="hero.link"
        >
          <SfLink
            class="big-link"
            :link="hero.link"
            :target="hero.target"
          />
        </template>
      </SfOHeroItem>
    </SfHero>
  </lazy-hydrate>
</template>
<script>
import { mapGetters } from 'vuex';
import { getThumbnailPath } from '@vue-storefront/core/helpers';
import SfOHeroItem from 'theme/components/storefront-override/SfOHeroItem'
import LazyHydrate from 'vue-lazy-hydration';
import {
  SfHero,
  SfButton,
  SfLink
} from '@storefront-ui/vue';
import DeviceType from 'theme/mixins/DeviceType';

const TOP_BANNER_POSITION_INDEX = '0';

export default {
  name: 'MBrandPageBanners',
  components: {
    LazyHydrate,
    SfOHeroItem,
    SfHero,
    SfButton,
    SfLink
  },
  mixins: [DeviceType],
  data () {
    return {
      isMounted: false,
      heroSettings: {
        type: 'carousel',
        animationTimingFunc: 'ease-out',
        animationDuration: 1000,
        rewindDuration: 2000,
        perView: 1,
        hoverpause: false
      }
    }
  },
  mounted () {
    this.isMounted = true;
  },
  computed: {
    ...mapGetters({
      getBrandBanners: 'promoted/getBrandBanners'
    }),
    preparedHeroes () {
      const filteredHomeBanners = this.getBrandBanners.filter((item) => item.position === TOP_BANNER_POSITION_INDEX);
      const sortedHeroes = filteredHomeBanners.sort((a, b) => a.sort_order > b.sort_order ? 1 : -1);

      sortedHeroes.forEach(e => {
        e.image = {
          mobile: getThumbnailPath(e.image_mobile),
          desktop: getThumbnailPath(e.image_desktop)
        }
      });

      return sortedHeroes
    }
  },
  created () {
    this.$store.dispatch('promoted/updateBrandBanners')
  }
}
</script>
<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

$slider-desktop-height: px2rem(150);
$slider-mobile-height: px2rem(116);

.sf-hero {
  margin-bottom: 0;
  width: 100%;
  margin: var(--spacer-10) 0 0 0;
  max-height: $slider-desktop-height;

  @include for-desktop {
    width: 67%;
    flex-grow: 1;
    margin-top: 0;
  }

  ::v-deep .glide__slides {
    height: $slider-desktop-height;

    @include for-tablet {
      height: $slider-mobile-height;
    }
  }

  &__controls {
    display: none;
  }

  &__banner-button {
    margin: var(--spacer-5) 0 0;
    font-weight: var(--font-medium);
    font-size: var(--font-size-14);
    --button-width: 110px;
    --button-height: 40px;
  }

  &-item {
    --hero-item-height: auto;
    padding: var(--spacer-15) 0 0 var(--spacer-20);
    min-height: $slider-mobile-height;
    background-size: auto;

    @include for-tablet {
      padding: var(--spacer-20) 0 0 var(--spacer-30);
      min-height: $slider-mobile-height;
    }

    @include for-desktop {
      padding: var(--spacer-37) 0 0 var(--spacer-56);
      background-size: initial;
    }

    &__title {
      font-weight: var(--font-medium);
      font-size: var(--font-size-18);
      max-width: 55%;

      @include for-tablet {
        font-size: var(--font-size-20);
        max-width: inherit;
      }

      @include for-desktop {
        font-weight: var(--font-bold);
        font-size: var(--font-size-24);
        max-width: inherit;
      }
    }
  }

  &__bullets {
    padding-top: var(--spacer-10);
    bottom: auto;
  }
}
</style>
